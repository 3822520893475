import React, { useState, useEffect } from 'react';

function TypingIndicator({ isTyping }) {
  const [thinkingText, setThinkingText] = useState('Thinking');
  
  useEffect(() => {
    if (!isTyping) return;
    
    const texts = ['Thinking', 'Düşünüyorum'];
    let index = 0;
    
    const interval = setInterval(() => {
      index = (index + 1) % texts.length;
      setThinkingText(texts[index]);
    }, 2000); // Switch every 2 seconds
    
    return () => clearInterval(interval);
  }, [isTyping]);
  
  if (!isTyping) return null;

  return (
    <div className="typing-indicator-container">
      <span className="thinking-text">{thinkingText}</span>
      <div className="typing-indicator">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default TypingIndicator;