import React, { useRef, useEffect } from 'react';
import Message from './Message';

function ChatWindow({ messages, openModal }) {
  const chatWindowRef = useRef(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-window" ref={chatWindowRef}>
      {messages.map((message, index) => (
        <Message 
          key={index} 
          sender={message.sender} 
          text={message.text} 
          isTyping={message.isTyping}
          htmlContent={message.htmlContent} // Pass HTML content
          imageData={message.imageData}
          openModal={openModal}
        />
      ))}
    </div>
  );
}

export default ChatWindow;
