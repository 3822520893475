// App.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import CompanyList from './components/CompanyList';
import ChatWindow from './components/ChatWindow';
import ChatForm from './components/ChatForm';
import Header from './components/Header';
import Welcome from './components/Welcome';
import Modal from './components/Modal';
import Home from './components/home/Home';
import './styles.css';


// src/config.js
// Use an environment variable (e.g. REACT_APP_API_BASE_URL) if available; otherwise default to localhost:3000
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || window.location.origin;


function App() {
  const [messages, setMessages] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [isCompanyListOpen, setIsCompanyListOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('userId')) {
      const userId = 'user-' + Math.random().toString(36).substr(2, 9);
      localStorage.setItem('userId', userId);
    }
  }, []);

  const createNewThread = async () => {
    setIsLoading(true);
    try {
      console.log('Creating new thread...');
      console.log('API URL:', `${API_BASE_URL}/api/create-thread`);
      
      const response = await fetch(`${API_BASE_URL}/api/create-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers));
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`Failed to create thread: ${response.status} ${errorText}`);
      }
  
      const data = await response.json();
      console.log('Thread created successfully:', data);
      setThreadId(data.threadId);
      setMessages([]);
      return data.threadId;
    } catch (error) {
      console.error('Error in createNewThread:', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode');
  };

  const handleStartChat = async () => {
    setIsTransitioning(true);
    const newThreadId = await createNewThread();
    if (newThreadId) {
      setIsChatVisible(true);
      setThreadId(newThreadId);
    }
    setIsTransitioning(false);
  };

  const escapeMarkdown = (text) => {
    return text.replace(/`/g, '\\`');
  };

  // ...existing code...

// Update the sendMessage function in App.js
const sendMessage = async (message) => {
  if (!threadId) {
    console.error('No thread ID available');
    return;
  }

  const newUserMessage = { sender: 'user', text: message };
  const newAssistantMessage = { sender: 'assistant', text: '', isTyping: true };
  setMessages(prevMessages => [...prevMessages, newUserMessage, newAssistantMessage]);

  try {
    const response = await fetch(`${API_BASE_URL}/api/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message,
        userId: localStorage.getItem('userId'),
        threadId: threadId
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let assistantResponse = '';
    let htmlContent = null;

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      const lines = chunk.split('\n');
      
      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = line.slice(6).trim();
          
          if (data === '[DONE]') {
            break;
          }
          
          try {
            const parsedData = JSON.parse(data);
            console.log("Received data chunk:", parsedData); // Debug log
            
            // Handle HTML content if present
            if (parsedData.code) {
              htmlContent = parsedData.code;
            }
            
            // Handle text content if present
            if (parsedData.text) {
              assistantResponse += parsedData.text;
            }
            
            // Update message state
            setMessages(prevMessages => {
              const newMessages = [...prevMessages];
              const lastMessage = newMessages[newMessages.length - 1];
              if (lastMessage.sender === 'assistant') {
                lastMessage.text = assistantResponse;
                lastMessage.htmlContent = htmlContent; // Make sure to include HTML content
                lastMessage.isTyping = false;
              }
              return newMessages;
            });
          } catch (e) {
            console.error('Error parsing chunk:', e, data);
          }
        }
      }
    }
  } catch (error) {
    console.error('Error sending message:', error);
    setMessages(prevMessages => {
      const newMessages = [...prevMessages];
      const lastMessage = newMessages[newMessages.length - 1];
      if (lastMessage.sender === 'assistant') {
        lastMessage.text = 'Sorry, an error occurred while processing your request.';
        lastMessage.isTyping = false;
        lastMessage.error = true;
      }
      return newMessages;
    });
  }
};

// ...existing code...

  return (
    <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>
      <AnimatePresence mode="wait">
        {!isChatVisible && !isTransitioning && (
            <button
              className="chat-widget-button"
              onClick={handleStartChat}
            >
              <div className="widget-icon">
                💬
              </div>
            </button>
          )}
        {isLoading ? (
          <motion.div 
            className="loading-screen"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ 
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96]
            }}
          >
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="loading-content"
            >
              <motion.div 
                className="loading-icon"
                animate={{ 
                  rotate: 360,
                  scale: [1, 1.1, 1],
                  opacity: [0.5, 1, 0.5]
                }}
                transition={{ 
                  rotate: { duration: 1.5, repeat: Infinity, ease: "linear" },
                  scale: { duration: 2, repeat: Infinity },
                  opacity: { duration: 2, repeat: Infinity }
                }}
              />
              <motion.h2 
                className="loading-title"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                Yükleniyor...
              </motion.h2>
              <motion.p 
                className="loading-subtitle"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                Lütfen bekleyiniz...
              </motion.p>
            </motion.div>
          </motion.div>
        ) : !isChatVisible ? (
          <motion.div
            key="home"
            initial={{ opacity: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ 
              duration: 0.3,
              ease: [0.43, 0.13, 0.23, 0.96]
            }}
          >
            <Home 
              darkMode={darkMode} 
              toggleDarkMode={toggleDarkMode}
              onStartChat={handleStartChat}
            />
          </motion.div>
        ) : (
          <motion.div
            key="chat"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="chat-container"
          >
            <Header 
              darkMode={darkMode} 
              toggleDarkMode={toggleDarkMode} 
              isCompanyListOpen={isCompanyListOpen}
              setIsCompanyListOpen={setIsCompanyListOpen}
              onBackToHome={() => setIsChatVisible(false)}
            />
            <main className="main-content">
              {messages.length === 0 ? (
                <Welcome 
                  onSendMessage={sendMessage} 
                  setIsCompanyListOpen={setIsCompanyListOpen}
                />
              ) : (
                <ChatWindow messages={messages} openModal={setModalImage} />
              )}
              <ChatForm onSendMessage={sendMessage} onClearChat={createNewThread} />
            </main>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default App;
