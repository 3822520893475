import { useEffect, useRef } from 'react';

const HTMLRenderer = ({ content }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && content) {
      // Clear any existing content
      containerRef.current.innerHTML = '';
      
      // Create a wrapper div
      const wrapper = document.createElement('div');
      wrapper.innerHTML = content;
      
      // Handle external script tags first
      const scripts = wrapper.getElementsByTagName('script');
      const scriptsToLoad = Array.from(scripts).map(script => {
        const newScript = document.createElement('script');
        
        // Copy all attributes
        Array.from(script.attributes).forEach(attr => {
          newScript.setAttribute(attr.name, attr.value);
        });
        
        // Store the content if it's an inline script
        const content = script.innerHTML;
        newScript.innerHTML = content;
        
        // Remove the original script tag
        script.parentNode.removeChild(script);
        
        return newScript;
      });
      
      // Add the HTML content
      containerRef.current.appendChild(wrapper);
      
      // Load scripts sequentially
      const loadScripts = async () => {
        for (const script of scriptsToLoad) {
          if (script.src) {
            // For external scripts, wait for them to load
            await new Promise((resolve, reject) => {
              script.onload = resolve;
              script.onerror = reject;
              document.body.appendChild(script);
            });
          } else {
            // For inline scripts, execute them immediately
            document.body.appendChild(script);
          }
        }
      };
      
      loadScripts().catch(console.error);
    }
  }, [content]);

  return (
    <div 
      ref={containerRef}
      className="html-visualization-container w-full overflow-x-auto my-4 rounded-lg"
    />
  );
};

export default HTMLRenderer;